import React, { useState, useEffect } from 'react'
import { Button, Drawer, IconButton, List } from '@mui/material'
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material'

import { useTheme } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import ControlIcon from '../Control/Icon'
import UsersIcon from '../Users/Icon'
import ChassisIcon from '../Chassis/Icon'
import ConstructorsIcon from '../Constructors/Icon'
import VehicleTypeIcon from '../VehicleType/Icon'
import OperatorsIcon from '../Operators/Icon'
import ContractorsIcon from '../Contractors/Icon'
import MembersIcon from '../Members/Icon'
import DepotsIcon from '../Depots/Icon'
import EmailsIcon from '../Emails/Icon'

// components
import SidebarLink from './components/SidebarLink/SidebarLink'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext'
import { useUserState, hasPermission } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

function Sidebar({ location }) {
  const classes = useStyles()
  const theme = useTheme()

  // global
  const { isSidebarOpened } = useLayoutState()
  const layoutDispatch = useLayoutDispatch()
  const { t } = useTranslation()
  const { user } = useUserState()

  // local
  const [isPermanent, setPermanent] = useState(true)

  const structure = []
  if (hasPermission(Permissions.PAGE_DASHBOARD_ROOT, user)) {
    structure.push({
      label: t('sidebar.dashboard'),
      link: '/dashboard',
      icon: <HomeIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_VEHICLES_TYPE_ROOT, user)) {
    structure.push({
      label: t('sidebar.vehicle-types'),
      icon: <VehicleTypeIcon />,
      link: '/vehicle-types/list',
    })
  }
  if (hasPermission(Permissions.PAGE_CONTROLS_ROOT, user)) {
    structure.push({
      label: t('sidebar.controls'),
      link: '/controls/list',
      icon: <ControlIcon />,
      children: [
        { label: t('common.list'), link: '/controls/list' },
        { label: t('common.detail'), link: '/controls/detail-list' },
      ],
    })
  }
  if (hasPermission(Permissions.PAGE_CHASSIS_ROOT, user)) {
    structure.push({
      label: t('sidebar.chassis'),
      link: '/chassis/list',
      icon: <ChassisIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_CONSTRUCTORS_ROOT, user)) {
    structure.push({
      label: t('sidebar.constructors'),
      link: '/constructors/list',
      icon: <ConstructorsIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_MEMBERS_ROOT, user)) {
    structure.push({
      label: t('sidebar.members'),
      link: '/members/list',
      icon: <MembersIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_OPERATORS_ROOT, user)) {
    structure.push({
      label: t('sidebar.operators'),
      link: '/operators/list',
      icon: <OperatorsIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_CONTRACTORS_ROOT, user)) {
    structure.push({
      label: t('sidebar.contractors'),
      link: '/contractors/list',
      icon: <ContractorsIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_DEPOTS_ROOT, user)) {
    structure.push({
      label: t('sidebar.depots'),
      link: '/depots/list',
      icon: <DepotsIcon />,
    })
  }
  if (hasPermission(Permissions.PAGE_EMAILS_LISTS_ROOT, user)) {
    structure.push({
      label: t('sidebar.email-lists'),
      link: '/email-lists/list',
      icon: <EmailsIcon />,
    })
  }
  const canManagePermissions = hasPermission(
    Permissions.PAGE_PERMISSION_ROOT,
    user
  )
  if (canManagePermissions) {
    structure.push({
      label: t('sidebar.users'),
      icon: <UsersIcon />,
      link: '/users/list',
      children: [
        { label: t('sidebar.permissions'), link: '/users/permissions' },
        { label: t('sidebar.users-list'), link: '/users/list' },
      ],
    })
  } else if (hasPermission(Permissions.PAGE_USERS_ROOT, user)) {
    structure.push({
      label: t('sidebar.users'),
      icon: <UsersIcon />,
      link: '/users/list',
    })
  }

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth
    const breakpointWidth = theme.breakpoints.values.md
    const isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }

  function downloadUsersManual() {
    window.open(
      "https://catp-backend-prod-bucket.s3.eu-west-3.amazonaws.com/user-manual/Manuel+d'utilisation.pdf",
      '_blank'
    )
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <List className={classes.sidebarList}>
          {structure.map((link) => (
            <SidebarLink
              key={link.label}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
        {hasPermission(Permissions.PAGE_PERMISSION_ROOT, user) && (
          <Button
            color="primary"
            style={{
              marginBottom: '1rem',
              marginLeft: '1rem',
              marginRight: '1rem',
              whiteSpace: 'normal', // Allows text to wrap
              paddingTop: '0.5rem', // Adjust padding for better spacing
              paddingBottom: '0.5rem',
            }}
            onClick={() => downloadUsersManual()}
          >
            {t('sidebar.users-manual')}
          </Button>
        )}
      </div>
    </Drawer>
  )
}

export default withRouter(Sidebar)
